
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
    name: "sign-in",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const router = useRouter();


        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const login = Yup.object().shape({
            Username: Yup.string().required().label("Username"),
            Password: Yup.string().min(4).required().label("Password"),
        });

        //Form submit function
        const onSubmitLogin = (values) => {
            // Clear existing errors
            store.dispatch(Actions.LOGOUT);

            if (submitButton.value) {
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            // Send login request
            store.dispatch(Actions.LOGIN, values)
                .then(() => {
                    Swal.fire({
                        text: "Sisteme başarılı bir şekilde giriş yaptınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam, devam et!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        // Go to page after successfully login
                        router.push({name: "dashboard"});
                    });
                })
                .catch(() => {
                    Swal.fire({
                        text: "Hatalı veya eksik veri girişi!",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Yeniden dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                    });
                });

            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
        };

        return {
            onSubmitLogin,
            login,
            submitButton,
        };
    },
});
